import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  {  createRef } from 'react';
import {  FormikProps } from 'formik';
export interface DeleteAccountValues {
  password: string,
  confirmPassword: string,
  comment: string,
  isShowPassword: boolean,
  isShowConfirmPassword: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string
  loading: boolean
  isShowModalConfirmDelete: boolean
  dataDelete: {
    password: string,
    comment: string
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DeleteAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  initialDeleteAccountValues: DeleteAccountValues
  apiDeleteAccountCallId: string = "";
  formikDeleteAccountRef = createRef<FormikProps<DeleteAccountValues>>();
  regexMatKhau = /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])(?=.*[A-Z])[A-Za-z\d@$!%*?&]{8,}$/
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.initialDeleteAccountValues = {
      password: "",
      confirmPassword: "",
      comment: "",
      isShowPassword: false,
      isShowConfirmPassword: false
    }
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      token:"",
      loading: false,
      isShowModalConfirmDelete: false,
      dataDelete: {password: "", comment: ""}
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token =  localStorage.getItem("token") ?? ""
      this.setState({ token: token });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseMessage(message)
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }
  handleResponseMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (responseJson.errors) {
      const errorString = JSON.stringify(responseJson.errors)
      if(errorString.includes('token')){
        this.props.navigation.navigate("EmailAccountLoginBlock")
        return
      }
      this.parseApiCatchErrorResponse(responseJson.errors);
      return
    }

    if (apiRequestCallId === this.apiDeleteAccountCallId) {
      this.setState({loading: false})
      this.handleCloseModalConfirmDelete()
      if (responseJson.data) {
        localStorage.removeItem("token")
        this.props.navigation.replace("EmailAccountLoginBlock")
      } else {
        //Check Error Response
        this.formikDeleteAccountRef.current?.setFieldError("password", responseJson.errors[0]?.password)
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }
  getErrorClassName = (errorMessage: string | null | undefined, touchedField: boolean | undefined) => {
    return errorMessage && touchedField ? "error" : ""
  }
  isErrorField = (errorMessage: string | null | undefined, touchedField: boolean | undefined) => {
    return errorMessage && touchedField
  }
  handleDeleteAccount = () => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };

    const httpBody = {
     data: {
      password: this.state.dataDelete.password,
      confirm_password: this.state.dataDelete.password,
     comment: this.state.dataDelete.comment
     }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteAccountCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiDeleteHospiceAccountUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSubmitDeleteAccount = (password: string, comment: string) => {
    this.setState({
      dataDelete: {password,  comment},
      isShowModalConfirmDelete: true
    })
  }
  handleCloseModalConfirmDelete = () => {
    this.setState({isShowModalConfirmDelete: false})
  }
  handleGoBack = () => {
    this.props.navigation.goBack();
  }
  handleGoToDashboard = () => {
    const dashboardScreen = "Dashboard"
    this.props.navigation.navigate(dashboardScreen)
  }
  handleGoToSettings = () => {
    const settingScreen = "Settings2"
    this.props.navigation.navigate(settingScreen)
  }
  // Customizable Area End
}
