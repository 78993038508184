// Customizable Area Start
import React from 'react'
import ContentManagementController, {
  Props,
} from "./ContentManagementController";
import AuthorizedHeader from "../../../components/src/AuthorizedHeader"
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import Glossaries from "../../../components/src/Glossaries.web";

export default class ContentManagementGlossaries extends ContentManagementController {
  constructor(props: Props) {
    super(props);
  }

  renderHeader = () => (
      <AuthorizedHeader
        navigation={this.props.navigation}
        data-test-id={"authorized-header"}
      />
  )

  render() {
    return (
      <Body sx={{ p: { xs: '0 0 20px', lg: '20px 20px 0' } }}>
        {this.renderHeader()}
        {this.state.glossariesData && (
          <Glossaries
            data={this.state.glossariesData}
            navigation={this.props.navigation}
          />)}
      </Body>
    )
  }
}

const Body = styled(Box)({
  background: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: "hidden",
  paddingBottom: 0
});

// Customizable Area End
